<template>
  <v-container v-if="!_.isEmpty(adminEditable)">
    <!-- Banner header -->
    <Banner :title="'Ficha usuario Administrador'" />

    <!-- Form profile data -->
    <v-card class="my-8 pa-5">
      <v-card-text class="pa-5">
        <v-form ref="formEditAdmin" v-model="formEditAdminValid">
          <v-text-field v-model="adminEditable.role" label="Rol de administrador" readonly />
          <v-text-field v-model="adminEditable.first_name" label="Nombre" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field v-model="adminEditable.last_name" label="Apellidos" :rules="formRules.textRules" :readonly="!editable" />
          <CountryAutocomplete :countryId="adminEditable.country" :editable="editable" @selected="adminEditable.country = $event" />
          <v-text-field v-model="adminEditable.email" label="Email" :rules="formRules.emailRules" :readonly="!editable" />
          <v-text-field v-model="adminEditable.phone" label="Teléfono" :rules="formRules.phoneRules" :readonly="!editable" />
          <v-text-field
            v-model="adminEditable.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            label="Contraseña"
            :rules="formRules.passwordNotRequiredRules"
            :readonly="!editable"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-icon v-if="!editable && permissionsCardsActions.profileData.edit.includes(user.role)" @click="editable = true">edit</v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";
import Banner from "@/components/elements/Banner";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete";

export default {
  name: "Admin",
  components: {
    Banner,
    CountryAutocomplete
  },
  mixins: [FormRulesMixin, FormatDateMixin],
  data() {
    return {
      // Cards actions permissions
      permissionsCardsActions: {
        profileData: {
          edit: ["SUPERADMIN"]
        }
      },

      // Form edit admin
      editable: false,
      formEditAdminValid: false,
      admin: {},
      adminEditable: {},
      showPassword: false
    };
  },
  computed: {
    ...mapState({
      admins: (state) => state.admins.admins,
      user: (state) => state.user.user
    }),
    _() {
      return _;
    }
  },
  watch: {
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    if (!this.admins.find((e) => e.id === this.$route.params.id)) this.$router.push("/admins"); // Push back if id does not exist
    this.formatFields();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    // Format fields editables
    formatFields() {
      const adminFromListener = this.admins.find((e) => e.id === this.$route.params.id);
      this.admin = adminFromListener;
      this.adminEditable = this._.cloneDeep(adminFromListener);
    },

    // Edit admin
    async edit() {
      if (this.$refs.formEditAdmin.validate()) {
        const adminEditableFormattedData = {
          id: this.adminEditable.id,
          first_name: this.adminEditable.first_name,
          last_name: this.adminEditable.last_name,
          email: this.adminEditable.email,
          phone: this.adminEditable.phone,
          country: this.adminEditable.country
        };
        if (this.adminEditable.password) adminEditableFormattedData.password = this.adminEditable.password;

        await this.$store.dispatch("admins/editAdmin", adminEditableFormattedData);

        this.formatFields();
        this.editable = false;
      }
    }
  }
};
</script>
