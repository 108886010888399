import dayjs from "dayjs";

export default {
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    formatDateL(date) {
      if (!date) return null;
      return dayjs(date).format("DD/MM/YYYY");
    },
    formatDateLWithTime(date) {
      if (!date) return null;
      return dayjs(date).format("DD/MM/YYYY - HH:mm:ss");
    },
    formatDateToISO8601(date) {
      return dayjs(date).toISOString();
    },
    dateNowFormated() {
      return dayjs().format("YYYY-MM-DD");
    },
    dateNextDayFormated() {
      return dayjs().add(1, "day").format("YYYY-MM-DD");
    },
    date30DaysBeforeToday() {
      return dayjs().subtract(30, "day").format("YYYY-MM-DD");
    },
    date15DaysAfter(date) {
      return dayjs(date).add(15, "day").format("YYYY-MM-DD");
    },
    // Return the Date of 18 years ago
    ageRestrinction() {
      return dayjs().subtract(18, "year").format("YYYY-MM-DD");
    }
  }
};
